/* eslint-disable no-shadow */
import {
  IS_ZONE_OF_RELEVANCE_ENABLED,
  IS_LOYALTY_FALLBACK,
  GET_BRAND,
  GET_ZOR_CARDS,
} from '@types/getters';
import {
  SET_RVI_IDS,
  SET_ZOR_INFO,
  SET_ZOR_CARDS,
  SET_IS_LOYALTY_FALLBACK,
  SET_PROS_ZONES_INFO,
} from '@types/mutations';
import { FETCH_ZOR_CARDS } from '@types/actions';
import { isEmpty, userInfo } from '../../utils';
import { getRviCard, getProsCards } from './helpers';
import { MAX_CARDS_COUNT } from './constants';

export function state() {
  return {
    rviIds: [],
    rviProduct: {},
    zorInfo: {},
    isZoneOfRelevanceEnabled: false,
    prosZonesInfo: {},
    zorCards: [],
    isLoyaltyFallback: false,
  };
}
export const getters = {
  [GET_BRAND](state, _, rootState) {
    return rootState.envProps.entryPoint;
  },
  [IS_ZONE_OF_RELEVANCE_ENABLED](state) {
    return state.isZoneOfRelevanceEnabled;
  },
  [IS_LOYALTY_FALLBACK](state) {
    return state.isLoyaltyFallback;
  },
  [GET_ZOR_CARDS](state) {
    return state.zorCards;
  },
};

export const mutations = {
  [SET_RVI_IDS](state, payload) {
    state.rviIds = payload;
  },
  [SET_ZOR_INFO](state, canvasObj) {
    if (isEmpty(canvasObj)) {
      return;
    }

    state.isZoneOfRelevanceEnabled = canvasObj.meta.properties.enableZoneOfRelevance;

    if (!state.isZoneOfRelevanceEnabled) {
      return;
    }

    const zorRow = canvasObj.rows.find(
      (row) => row?.row140?.attributes?.isZorDashboard,
    );

    if (!zorRow) {
      return;
    }

    const prosZonesInfo = {};
    const prosZones = zorRow.row140.zones[0].carousel.slides
      .filter((elem) => elem.prosProductPoolMedia)
      .map((elem) => elem.prosProductPoolMedia);
    prosZones.forEach((prosZone) => {
      prosZonesInfo[prosZone.zoneName.toLowerCase()] = {
        context: prosZone.context,
        maxRecs: prosZone.maxRecs,
      };
    });

    state.prosZonesInfo = prosZonesInfo;
  },
  [SET_PROS_ZONES_INFO](state, payload) {
    state.prosZonesInfo = payload;
  },
  [SET_ZOR_CARDS](state, payload) {
    state.zorCards = payload;
  },
  [SET_IS_LOYALTY_FALLBACK](state, payload) {
    state.isLoyaltyFallback = payload;
  },
};

export const actions = {
  async [FETCH_ZOR_CARDS](
    {
      commit, state, rootState, getters,
    },
  ) {
    const isSignedIn = userInfo.isSignedIn();
    const deviceType = rootState.envProps?.headers?.deviceType;

    const prosCards = await getProsCards({
      prosZonesInfo: state.prosZonesInfo,
      brand: getters[GET_BRAND],
      isSignedIn,
      deviceType,
    });

    if (isEmpty(prosCards)) {
      commit(SET_IS_LOYALTY_FALLBACK, true);
      return;
    }

    const rviCard = await getRviCard({
      rviIds: state.rviIds,
      count: 1,
      isSignedIn,
      brand: getters[GET_BRAND],
    });

    const loyaltyPlaceholderCards = deviceType === 'DESKTOP'
      ? [{ cardType: 'loyalty' }, { cardType: 'loyalty' }]
      : [];

    const zorCards = [
      ...loyaltyPlaceholderCards,
      ...[...(!isEmpty(rviCard) ? [rviCard] : []), ...prosCards].slice(
        0,
        MAX_CARDS_COUNT,
      ),
    ];

    commit(SET_ZOR_CARDS, zorCards);
  },
};
