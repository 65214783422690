<template>
  <ZoneOfRelevanceTemplate
    :is-loyalty-fallback="isLoyaltyFallback"
    :is-desktop="isDesktop"
    :zor-cards="zorCards"
    :brand="brand"
    :carousel-title="sectionTitle"
  >
    <template #zor-slot>
      <component
        :is="loyaltyComponent"
        :full-width="isTablet || (isLoyaltyFallback && isDesktop)"
      />
    </template>
  </ZoneOfRelevanceTemplate>
</template>

<script setup>
import { onMounted, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { ZoneOfRelevance as ZoneOfRelevanceTemplate } from '@common-vue/media-components/src/components/zoneOfRelevance';
import {
  GET_ZOR_CARDS,
  IS_LOYALTY_FALLBACK,
} from '@types/getters';
import { SET_RVI_IDS } from '@types/mutations';
import { FETCH_ZOR_CARDS } from '@types/actions';
import ZOR_TITLE from './constants';

const store = useStore();
const isDesktop = computed(() => store.state.envProps?.headers?.deviceType === 'DESKTOP');
const isTablet = computed(() => store.state.envProps?.headers?.deviceType === 'TABLET');
const brand = computed(() => store.state?.envProps?.BRAND);
const isLoyaltyFallback = computed(
  () => store.getters[`zoneOfRelevanceStore/${IS_LOYALTY_FALLBACK}`],
);

const sectionTitle = ZOR_TITLE[brand.value];
// Remove the filter if Loyalty Card needs to be added to ZOR again
const zorCards = computed(
  () => store.getters[`zoneOfRelevanceStore/${GET_ZOR_CARDS}`].filter((item) => item?.cardType?.toUpperCase() !== 'LOYALTY'),
);
const LoyaltyCard = brand.value === 'mcom' ? () => import('../mcom/LoyaltyCard') : () => import('../bcom/LoyaltyCard');
const loyaltyComponent = computed(
  () => defineAsyncComponent({
    loader: LoyaltyCard,
    onError: () => { },
  }),
);

onMounted(async () => {
  const rviIds = (localStorage.getItem('recentlyViewedItems') || '')
    .split(',')
    .filter(Boolean);
  store.commit(`zoneOfRelevanceStore/${SET_RVI_IDS}`, rviIds);

  await store.dispatch(`zoneOfRelevanceStore/${FETCH_ZOR_CARDS}`);
});
</script>
<style lang="scss" scoped>
:deep(.v-button-wrapper) {
  margin-top: calc(100vw * 0.1625 / 2 - 16px);
  @media screen and (min-width: 1600px) {
    margin-top: 114px;
  }
}
</style>
